<template>
<div class="wrapper">
<!-- Navbar --> 
<topbar2></topbar2>
    <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <header2></header2>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h3 class="m-0">NEW ATUL DRY CLEANER</h3> 
            <small>In front of Nagar Palika Gate, Etawah</small>    

          </div><!-- /.col --> 
          <div class="col-sm-6" style="text-align:right;">
            <a v-bind:href="'/updateorder/'" class="btn btn-success btn-sm"><i class="fa fa-print"></i> Edit</a>&nbsp;
            <button class="btn btn-primary btn-sm"><i class="fa fa-print"></i> Print</button>            
          </div><!-- /.col -->                   
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    
        <!-- Main content -->
    <section class="content">
      <div class="container-fluid">        
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <section class="col-lg-12 connectedSortable">
              <div class="row">
<div class="col-md-6">
    <table class="table">
        <tr><td><b>Name</b></td><td>{{name}}</td></tr>
        <tr><td><b>Booking Date</b></td><td>{{pickup_date}}</td></tr>
    </table>
</div>
<div class="col-md-6">
    <table class="table">
        <tr><td><b>Mobile No.</b></td><td>{{mobile_no}}</td></tr>
        <tr><td><b>Delivery Date</b></td><td>{{delivery_date}}</td></tr>
    </table>
</div>
              </div>
            
<table class="table">
    <thead>
      <tr>       
        <th class="item">item</th>
        <th class="qty">Qty.</th>
        <th class="amount">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="d in item_list" v-bind:key="d.id">       
        <td>{{ d.item_name }}</td>      
        <td>{{ d.quantity }}</td>  
        <td>Rs. {{ d.amount }}</td>              
      </tr>
      <tr><td colspan="3"><b>Additional Charge</b></td></tr>
      <tr v-for="d in additional_charge" v-bind:key="d.id">       
        <td>{{ d.item_name }}</td>      
        <td>{{ d.quantity }}</td>  
        <td>Rs. {{ d.amount }}</td>              
      </tr>


<tr><td rowspan="5"><b>Receipt No. {{invoice_no}}</b></td><td><b>Total</b></td><td>Rs. {{grand_amount}}</td></tr>
<tr><td><b>Discount</b></td><td>Rs. {{discount}}</td></tr>
<tr><td><b>Grand Total</b></td><td>Rs. {{grand_amount}}</td></tr>
<tr><td><b>Advance</b></td><td>Rs. {{paid_amount}}</td></tr>
<tr><td><b>Balance</b></td><td>Rs. {{balance}}</td></tr>

    </tbody>
  </table>



          </section>
          <!-- /.Left col -->
        </div>
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>


  
  </div>
  <footer2></footer2> 
</div>
</template>

<script>
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {  
  components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  data() {
    return {
      invoice_id: localStorage.getItem('invoice_id'),       
      item_list:[],
      additional_charge:[],       
    };
  },
  
  mounted(){
    axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/getorderdetails",  {
    invoice_id: localStorage.getItem('invoice_id'),    
	})
      .then(res => {
        //alert(res.data.data)
        this.invoice_details = res.data.data[0].invoice_details;    
        this.item_list = res.data.data[0].item_list;
        this.additional_charge = res.data.data[0].additional_charge;   
        
        this.name = res.data.data[0].invoice_details[0].name
        this.pickup_date = res.data.data[0].invoice_details[0].pickup_date
        this.booking_date = res.data.data[0].invoice_details[0].booking_date
        this.delivery_date = res.data.data[0].invoice_details[0].delivery_date
        this.discount = res.data.data[0].invoice_details[0].discount
        this.net_amount = res.data.data[0].invoice_details[0].net_amount
        this.grand_amount = res.data.data[0].invoice_details[0].grand_amount
        this.paid_amount = res.data.data[0].invoice_details[0].paid_amount
        this.balance = res.data.data[0].invoice_details[0].balance
        this.order_type = res.data.data[0].invoice_details[0].order_type
        this.client_id = res.data.data[0].invoice_details[0].client_id
        this.mobile_no = res.data.data[0].invoice_details[0].mobile_no
        this.invoice_no = res.data.data[0].invoice_details[0].invoice_no        

        console.log(res.data.data)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })
  }
};
</script>

<style>
.connectedSortable { background: #fff; }
.item {border: 1px solid #333;}
.qty {border: 1px solid #333; width: 150px;}
.amount {border: 1px solid #333; width: 150px;}


  @import '../../assets/dist/css/daterangepicker.css'  

  
</style>

